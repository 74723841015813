<template>
	<div
		class="bg-login flex-column justify-center full-height"
	>
		<div
			class="bg-login-content "
		>
			<div
				class=" text-center"
			>
				<div
					v-if="$production && false"
					class="mt-20 mb-30 size-px-24 font-weight-bold vertical-middle"
				><img :src="$shop_logo" style="width: 100%"></div>
				<div
					v-else
					class="bg-base color-white pa-10 "
				>{{ $production_title }}</div>
			</div>

			<div class="mt-30 pa-10 justify-space-between gap-10">
				<button
					class="btn btn-primary ptb-30"
					@click="login"
				>로그인</button>

				<button
					class="btn btn-primary"
					@click="guest"
				>비회원 구매</button>
			</div>

			<div
				class="mt-30"
			>
				<button
					class="btn btn-identify"
					@click="join"
				>회원 가입</button>
			</div>

		</div>
	</div>
</template>

<script>

import { Base64 } from 'js-base64'

export default{
	name: 'Recomm'
	,props: ['Axios', 'codes']
	,data: function() {
		return {
			program: {
				name: '로그인'
				, code: 'login'
				, top: false
				, bottom: false
				, title: false
				, bland: false
				, wrap: 'loginNew'
			}
			,title: process.env.VUE_APP_TITLE_DEV
			,item: {
				member_id: ''
				,member_pw: ''
			}
			,is_id_save: false
			,is_auto_login: false
			,error: {
				type: ''
				,msg: ''
			}
			,is_id_error: false
			,is_pw_error: false
			,is_on_pw: false
			, code: this.$route.params.code
		}
	}
	,computed: {
		isIdInput: function(){
			if(this.item.member_id){
				return true
			}else{
				return false
			}
		}
		, isPwInput: function(){
			if(this.item.member_pw){
				return true
			}else{
				return false
			}
		}
		,toggleType: function(){
			if(this.is_on_pw){
				return 'text'
			}else{
				return 'password'
			}
		}
	}
	,methods: {
		login: async function(){
			this.$router.push({ name: 'Login'})
		}
		, join: function (){
			this.$router.push({ name: 'Join', params: { code: this.$route.params.code}})
		}
		, guest: function (){
			this.$router.push({ name: 'Guest', params: { code: this.$route.params.code}})
		}

	}
	,mounted() {
		if(localStorage.getItem(process.env.VUE_APP_NAME + 'S')){
			this.is_id_save = Base64.decode(localStorage.getItem(process.env.VUE_APP_NAME + 'S'))
			this.item.member_id = Base64.decode(localStorage.getItem(process.env.VUE_APP_NAME + 'S'))
		}

		if(localStorage.getItem(process.env.VUE_APP_NAME + 'A')){
			this.is_auto_login = Base64.decode(localStorage.getItem(process.env.VUE_APP_NAME + 'A'))
			this.item.member_id = Base64.decode(Base64.decode(localStorage.getItem(process.env.VUE_APP_NAME + 'N')))
			this.item.member_pw = Base64.decode(Base64.decode(localStorage.getItem(process.env.VUE_APP_NAME + 'P')))
			this.login()
		}
	}
	,created: function(){
		this.$emit('onLoad', this.program)

	}
	,watch: {

	}
}
</script>

<style>
.bg-login { background-color: #eee; padding: 10px;}
.bg-login-content { background-color: white; border-radius: 5px}

.auto-login { vertical-align: middle; font-size: 14px; }

.login-menu { }
.login-menu a { font-size: 14px; font-weight: 400}

.toggle-password { position: relative; }
.toggle-password .toggle { position: absolute; right: 10px; top: 20px; color: #bbb}
.toggle-password .toggle.on { color: #0f6ecd}

.error-underline { background: none; border-bottom: 1px solid red !important;}

</style>